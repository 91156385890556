import React, { useState } from "react";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { ActionIcon, Button, Tooltip } from "@common/components";
import { Cross1Icon, ImageIcon } from "@modulz/radix-icons";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { useScroll } from "@use-gesture/react";
import { useProject } from "../ProjectContext";
import { useMantineTheme } from "@mantine/core";
import { updateProjectPost } from "../../../../requests/project/project";
import { notification } from "@common/utils/notification";

interface ProjectCoverProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

export const ProjectCover: React.FC<ProjectCoverProps> = ({ form }) => {
  const theme = useMantineTheme();
  const { view, project } = useProject();
  const [uploadCover, setUploadCover] = useState(false);
  const [top, setTop] = useState(0);

  useScroll(
    ({ delta }) => {
      setTop((prev) => prev - delta[1] / 2);
    },
    {
      target: document.getElementById("scroll-container")!,
    }
  );

  const handleCoverUpload = async (url: string | null) => {
    form.setValues({ ...form.values, coverArt: url }, true);
    if (!project?.id) return;

    const { error } = await updateProjectPost(project.id, {
      coverArt: url,
    });

    if (error) return notification.error(error.message);
  };

  return (
    <div className="h-0 overflow-visible">
      <div
        className="fixed left-0 w-full h-96 -z-10"
        style={{
          background: form.values.coverArt
            ? "inherit"
            : form.values.albumArtColor
            ? theme.fn.rgba(form.values.albumArtColor, 0.8)
            : "inherit",
          backgroundImage: form.values.coverArt
            ? `url(${form.values.coverArt})`
            : undefined,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          top,
        }}
      >
        {(form.values?.coverArt || form.values?.albumArtColor) && (
          <div
            className="absolute left-0 top-[4.5rem] w-full h-[calc(100%-4.5rem)] -z-20"
            style={{
              mixBlendMode: "luminosity",
              background:
                "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgb(128,128,128) 100%), url(/images/noise.svg)",
              filter: "contrast(3) invert(1)",
            }}
          />
        )}

        <div
          className="absolute left-0 top-[4.5rem] w-full h-[calc(100%-4.5rem)] -z-10"
          style={{
            background:
              "linear-gradient(180deg, rgba(0,0,0,0) 0%, #040711 100%)",
          }}
        />

        <UploadImageModal
          opened={uploadCover}
          onClose={() => setUploadCover(false)}
          onUpload={handleCoverUpload}
          targetWidth={1920}
          targetHeight={640}
          projectId={project?.id}
        />
      </div>

      {view !== "viewer" && (
        <div className="container max-w-[128rem] relative">
          <div className="absolute top-0 left-0 flex items-center justify-end gap-2">
            <Button
              leftIcon={<ImageIcon />}
              size="xs"
              variant="light"
              color="dark"
              onClick={() => setUploadCover(true)}
            >
              Upload cover
            </Button>

            {form.values.coverArt && (
              <Tooltip label="Remove cover">
                <ActionIcon
                  size="sm"
                  variant="light"
                  color="red"
                  onClick={() => handleCoverUpload(null)}
                >
                  <Cross1Icon />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

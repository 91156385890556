import React, { useMemo, useRef, useState } from "react";
import { File, User } from "@server/entities";
import {
  Avatar,
  Button,
  Card,
  Divider,
  EmojiSelect,
  RichText,
} from "@common/components";
import { Comment } from "@common/components/Comment";
import Skeleton from "@common/components/Skeleton";
import Confirm from "@common/components/Confirm";
import { Trash } from "tabler-icons-react";
import { useUser } from "../../../contexts/UserContext";
import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import { Editor } from "@mantine/rte";
import { getRecoil } from "../../../contexts/RecoilNexus";
import { currTimeState } from "../../../contexts/Player";
import { createMarkup, deleteMarkup } from "../../../requests/project/markup";
import { uniqBy } from "lodash";
import { KeyedMutator } from "swr";

interface FileCommentsProps {
  file: File;
  isPlaying: boolean;
  mutate: KeyedMutator<any>;
}

export const FileComments: React.FC<FileCommentsProps> = ({
  file,
  isPlaying,
  mutate,
}) => {
  const { user } = useUser();
  const { comments } = file;
  const ref = useRef<Editor>();
  const [delId, setDelId] = useState<string | null>(null);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      fileId: file.id,
      type: "comment",
    },
  });

  const handleSubmit = async () => {
    const currTime = getRecoil(currTimeState);

    const { error } = await form.sendForm((values) =>
      createMarkup({
        ...values,
        start: isPlaying ? currTime : undefined,
      })
    );

    if (error) return;
    form.setFieldValue("title", "");
    await mutate();
  };

  const handleDelete = async () => {
    if (!delId) return;
    await deleteMarkup(delId);
    setDelId(null);
    await mutate();
  };

  const handleReply = async (user: User) => {
    ref.current?.getEditor().getModule("mention").insertItem(
      {
        id: user.id,
        value: user.displayName,
        denotationChar: "@",
      },
      true
    );
  };

  const taggableUsers = useMemo(() => {
    const sharedWithUsers = file.sharedWith;
    const commentUsers = comments.map((c) => c.reporter as User);

    return uniqBy(
      [...sharedWithUsers, ...commentUsers].filter((c) => c.id !== user?.id),
      (x) => x.id
    );
  }, []);

  return (
    <div>
      <h2 className="mt-6 mb-8">
        Comments{" "}
        {comments && comments.length > 0 && (
          <span className="text-dark-500 text-xl font-normal ml-3">
            ({comments.length})
          </span>
        )}
      </h2>

      {!comments ? (
        <Skeleton visible width="100%" height={100} />
      ) : (
        <Card className="bg-dark-800">
          <div>
            {comments.length === 0 ? (
              <p className="text-dark-300">No comments yet</p>
            ) : (
              <div className="flex flex-col gap-6">
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    onDelete={(id) => setDelId(id)}
                    onReply={handleReply}
                    mutate={mutate}
                    taggableUsers={taggableUsers}
                  />
                ))}
              </div>
            )}
          </div>

          {user && (
            <div>
              <Divider className="my-6" />

              <div className="flex gap-4">
                <Avatar user={user} className="mt-4" />

                <RichText
                  {...form.getInputProps("title")}
                  data-testid="file-comment-input"
                  ref={ref}
                  users={taggableUsers}
                  classNames={{ toolbar: "hidden" }}
                  className="flex-1"
                  placeholder="Leave a comment"
                  minRows={1}
                  autosize
                />
              </div>

              <div className="flex justify-between mt-4 pl-16">
                <EmojiSelect
                  onEmojiClick={({ emoji }) => {
                    ref.current?.getEditor().insertText(-1, emoji);
                  }}
                />

                <Button
                  data-testid="file-comment-submit"
                  onClick={handleSubmit}
                  loading={form.loading}
                >
                  Comment
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}

      <Confirm
        color="red"
        title="Delete comment"
        content="Are you sure you want to delete this comment?"
        icon={<Trash className="w-6 h-6" />}
        opened={!!delId}
        onClose={() => setDelId(null)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

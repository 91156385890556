import {
  Button,
  Card,
  Input,
  RichText,
  SegmentedControl,
} from "@common/components";
import Confirm from "@common/components/Confirm";
import { CONTROLS } from "@common/utils/editor";
import {
  CalendarIcon,
  EyeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { Warning } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteProject,
  updateProject,
  updateProjectPost,
} from "../../../../requests/project/project";
import { useProject } from "../ProjectContext";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { ProjectLinkedAccounts } from "./ProjectLinkedAccounts";
import { FormActionButtons } from "@common/components/FormActionButtons";

export interface ProjectSettingsProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

const ProjectSettings: React.FC<ProjectSettingsProps> = ({ form }) => {
  const navigate = useNavigate();
  const { project, view, mutate } = useProject();
  const [del, setDel] = useState(false);
  const [delLoading, setDelLoading] = useState(false);

  const handleDelete = async () => {
    if (!project?.id) return;
    setDel(false);
    setDelLoading(true);

    await deleteProject(project.id);

    setDelLoading(false);
    navigate("/projects");
  };

  const handlePermalink = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("permalink", e.target.value.replace(" ", "-").slice(20));
  };

  const handleSave = async () => {
    if (!project) return;

    const permalinkChanged = form.values.permalink !== project.permalink;

    const { error } = await form.sendForm(
      (values) =>
        values.file
          ? updateProject(project.id, values)
          : updateProjectPost(project.id, values),
      {
        dirtyOnly: true,
      }
    );

    if (error) return;

    if (permalinkChanged)
      window.location.replace(`/p/${form.values.permalink}`);

    await mutate();
  };

  return (
    <div className="grid grid-cols-1 gap-8" data-testid="project-settings">
      <Confirm
        color="red"
        icon={<Warning fontSize="medium" />}
        title="Delete this project?"
        content="Are you sure you want to delete this project and all associated data? This data will be unrecoverable after deletion."
        opened={del}
        onClose={() => setDel(false)}
        onConfirm={handleDelete}
      />

      <div className="flex flex-col md:flex-row gap-4">
        <Input
          {...form.getInputProps("permalink")}
          className="flex-1"
          data-testid="project-permalink"
          data-quick-assist-id="project-permalink"
          label="Permalink"
          value={`www.synqup.com.au/p/${form.values.permalink}`}
          onChange={handlePermalink}
        />

        <Input
          {...form.getInputProps("artist")}
          className="flex-1"
          data-testid="project-artist"
          data-quick-assist-id="project-artist"
          label="Artist"
        />
      </div>

      <RichText
        {...form.getInputProps("description")}
        data-testid="project-description"
        data-quick-assist-id="project-description"
        label="Description"
        description="Tell your audience more about what your project is about"
        controls={CONTROLS}
      />

      <div className="flex flex-col md:flex-row gap-8">
        <SegmentedControl
          {...form.getInputProps("privacy")}
          data-testid="project-privacy"
          data-quick-assist-id="project-privacy"
          size="md"
          className="mr-auto"
          label="Privacy"
          description="Choose who can view your project"
          data={[
            {
              value: "public",
              label: (
                <div
                  className="flex items-center"
                  data-testid="project-privacy-public"
                >
                  <EyeIcon className="w-5 h-5" />
                  <div className="ml-2">Public</div>
                </div>
              ),
            },
            {
              value: "scheduled",
              label: (
                <div
                  className="flex items-center"
                  data-testid="project-privacy-scheduled"
                >
                  <CalendarIcon className="w-5 h-5" />
                  <div className="ml-2">Scheduled</div>
                </div>
              ),
            },
            {
              value: "private",
              label: (
                <div
                  className="flex items-center"
                  data-testid="project-privacy-private"
                >
                  <LockClosedIcon className="w-5 h-5" />
                  <div className="ml-2">Private</div>
                </div>
              ),
            },
          ]}
        />

        <ProjectLinkedAccounts form={form} />
      </div>

      {view === "admin" ? (
        <Card
          variant="outlined"
          className="flex items-center justify-between border-red-500 border-opacity-70"
        >
          <div>
            <h3 className="text-red-500 mb-2">Delete this project</h3>
            <p>
              Permanently delete your project, including all associated data
              (stems, mixdowns, markups, documents, etc.)
            </p>
          </div>

          <Button
            data-testid="project-delete"
            loading={delLoading}
            color="red"
            onClick={() => setDel(true)}
          >
            Delete project
          </Button>
        </Card>
      ) : null}

      <FormActionButtons
        data-testid="project-save"
        form={form}
        onSave={handleSave}
      />
    </div>
  );
};

export { ProjectSettings };

import React, { useRef, useState } from "react";
import {
  createMarkup,
  deleteMarkup,
} from "../../../../requests/project/markup";
import { useProject } from "../ProjectContext";
import Skeleton from "@common/components/Skeleton";
import {
  Avatar,
  Button,
  Card,
  Divider,
  EmojiSelect,
  RichText,
} from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import { useUser } from "../../../../contexts/UserContext";
import { currTimeState, usePlayerState } from "../../../../contexts/Player";
import { getRecoil } from "../../../../contexts/RecoilNexus";
import { Trash } from "tabler-icons-react";
import Confirm from "@common/components/Confirm";
import { Editor } from "@mantine/rte";
import { User } from "@server/entities";
import { Comment } from "@common/components/Comment";

export const ProjectComments: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const ref = useRef<Editor>();
  const { user } = useUser();
  const { project, currMixdownId, markups, mutateMarkups } = useProject();
  const { playing } = usePlayerState(["playing"]);

  const comments = markups.filter((m) => m.type === "comment");

  const [delId, setDelId] = useState<string | null>(null);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      projectId,
      type: "comment",
    },
  });

  const handleSubmit = async () => {
    const currTime = getRecoil(currTimeState);

    const { error } = await form.sendForm((values) =>
      createMarkup({
        ...values,
        mixdownId: currMixdownId || undefined,
        start: playing ? currTime : undefined,
      })
    );

    if (error) return;
    form.setFieldValue("title", "");
    await mutateMarkups();
  };

  const handleDelete = async () => {
    if (!delId) return;
    await deleteMarkup(delId);
    setDelId(null);
    await mutateMarkups();
  };

  const handleReply = async (user: User) => {
    ref.current?.getEditor().getModule("mention").insertItem(
      {
        id: user.id,
        value: user.displayName,
        denotationChar: "@",
      },
      true
    );
  };

  if (!project) return null;

  return (
    <div>
      {!comments ? (
        <Skeleton visible width="100%" height={100} />
      ) : (
        <Card className="bg-dark-800">
          <div>
            {comments.length === 0 ? (
              <p className="text-dark-300">No comments yet</p>
            ) : (
              <div className="flex flex-col gap-6">
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    onDelete={(id) => setDelId(id)}
                    onReply={handleReply}
                    mutate={mutateMarkups}
                    taggableUsers={project.collaborators
                      .map((c) => c.user)
                      .filter((c) => c.id !== user?.id)}
                  />
                ))}
              </div>
            )}
          </div>

          {user && (
            <div>
              <Divider className="my-6" />

              <div className="flex gap-4">
                <Avatar user={user} className="mt-4" />

                <RichText
                  {...form.getInputProps("title")}
                  data-testid="project-comment-input"
                  ref={ref}
                  users={project?.collaborators
                    .map((c) => c.user)
                    .filter((c) => c.id !== user?.id)}
                  classNames={{ toolbar: "hidden" }}
                  className="flex-1"
                  placeholder="Leave a comment"
                  minRows={1}
                  autosize
                />
              </div>

              <div className="flex justify-between mt-4 pl-16">
                <EmojiSelect
                  onEmojiClick={({ emoji }) => {
                    ref.current?.getEditor().insertText(-1, emoji);
                  }}
                />

                <Button
                  data-testid="project-comment-submit"
                  onClick={handleSubmit}
                  loading={form.loading}
                >
                  Comment
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}

      <Confirm
        color="red"
        title="Delete comment"
        content="Are you sure you want to delete this comment?"
        icon={<Trash className="w-6 h-6" />}
        opened={!!delId}
        onClose={() => setDelId(null)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

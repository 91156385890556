import React, { useEffect, useState } from "react";
import { Card, SegmentedControl } from "@common/components";
import { getMyMarkups, useMyMarkups } from "../../../requests/project/markup";
import { RedDot } from "../../header/components";
import { TasksTable } from "../../tasks/components/TasksTable";

export const TasksChecklist: React.FC = () => {
  const [dateFilter, setDateFilter] = useState("week");
  const [noDueDateCount, setNoDueDateCount] = useState<number>();

  const { data, mutate } = useMyMarkups({
    assignedToMe: true,
    sortBy: "dueDate",
    sortDir: "ASC",
    dateFilter: dateFilter as "week" | "no-date",
  });

  useEffect(() => {
    getMyMarkups({
      assignedToMe: true,
      dateFilter: "no-date",
    })
      .then(({ data }) => setNoDueDateCount(data?.count))
      .catch(() => setNoDueDateCount(0));
  }, [data]);

  return (
    <Card className="bg-dark-800 p-6" data-quick-assist-id="tasks-checklist">
      <h2 className="mb-4">Tasks</h2>

      <SegmentedControl
        size="sm"
        data={[
          { value: "week", label: "This week" },
          {
            value: "no-date",
            label: (
              <div className="flex items-center gap-2">
                <>No due date</>
                {noDueDateCount && noDueDateCount > 0 ? (
                  <RedDot className="" count={noDueDateCount} />
                ) : (
                  <></>
                )}
              </div>
            ),
          },
        ]}
        onChange={setDateFilter}
      />

      <TasksTable mutate={mutate} tasks={data?.data} />
    </Card>
  );
};

import { getInputDefaults } from "@common/components/Input";
import React, { forwardRef, memo, useMemo } from "react";
import {
  Editor,
  RichTextEditor as MantineRichTextEditor,
  RichTextEditorProps,
} from "@mantine/rte";
import { CONTROLS } from "@common/utils/editor";
import { Input, InputWrapperProps } from "@mantine/core";
import { User } from "@server/entities";

export type RichTextProps = RichTextEditorProps &
  Omit<InputWrapperProps, "children"> & {
    // Users to include in mentionable array
    users?: User[];
  };

export const RichText = memo(
  forwardRef<Editor, RichTextProps>(({ users, ...props }, ref) => {
    const defaults = getInputDefaults(props);

    const mentions = useMemo(
      () =>
        users
          ? {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              mentionDenotationChars: ["@"],
              showDenotationChar: true,
              source: (searchTerm, renderList) => {
                const includesSearchTerm = users
                  .filter((item) =>
                    item.displayName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((item) => ({
                    id: item.id,
                    value: item.displayName,
                  }));
                renderList(includesSearchTerm);
              },
            }
          : undefined,
      [users?.length]
    );

    return (
      <Input.Wrapper {...defaults}>
        <MantineRichTextEditor
          ref={ref}
          controls={CONTROLS}
          mentions={mentions}
          {...defaults}
          {...props}
          sx={(theme) => ({
            backgroundColor: theme.colors.dark[8],
          })}
          classNames={{
            ...defaults.classNames,
            ...props.classNames,
          }}
        />
      </Input.Wrapper>
    );
  }),
  (prev, next) => prev.value === next.value
);

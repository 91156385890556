import axios from "axios";

export async function getOverallColor(url: string) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result as string;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Resize the canvas to 1x1 to average the image colors
      canvas.width = 1;
      canvas.height = 1;

      // Create a temporary larger canvas to saturate
      const tempCanvas = document.createElement("canvas");
      const tempContext = tempCanvas.getContext("2d");
      tempCanvas.width = img.width;
      tempCanvas.height = img.height;

      if (!context || !tempContext) {
        reject("Could not create canvas context");
        return;
      }

      // Apply saturation filter
      tempContext.filter = "saturate(2)"; // Increase saturation
      tempContext.drawImage(img, 0, 0);

      // Draw the saturated image to the smaller canvas
      context.drawImage(tempCanvas, 0, 0, 1, 1);

      // Get the pixel data from the canvas
      // @ts-ignore
      const [r, g, b] = context.getImageData(0, 0, 1, 1).data;

      // Set the extracted color
      resolve(`rgb(${r}, ${g}, ${b})`);
    };

    reader.onerror = reject;

    axios.get(url, { responseType: "blob" }).then((response) => {
      reader.readAsDataURL(response.data);
    });
  });
}

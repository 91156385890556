import {
  ActionIcon,
  Button,
  Modal,
  RouterTab,
  RouterTabs,
} from "@common/components";
import { Pencil2Icon } from "@modulz/radix-icons";
import React, { useState } from "react";
import { Route, Routes } from "react-router";
import { useProject } from "./ProjectContext";
import { CogIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { ProjectNote } from "./notes/ProjectNote";
import { CreateNote } from "./notes/CreateNote";
import { ProjectNotes } from "./notes/ProjectNotes";
import { ProjectCollaborators } from "./collaborators/ProjectCollaborators";
import { Skeleton } from "@mantine/core";
import { ProjectFiles } from "./files/ProjectFiles";
import { Books, Checkbox, Microphone, Share } from "tabler-icons-react";
import { Helmet } from "react-helmet";
import { ProjectSettings } from "./settings/ProjectSettings";
import { ProjectHeader } from "./home/ProjectHeader";
import useAsyncForm from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";

import { usePrompt } from "@common/utils/use-prompt";
import { ProjectTasks } from "./tasks/ProjectTasks";
import { ProjectMixdown } from "./home/ProjectMixdown";
import { ProjectDescription } from "./home/ProjectDescription";
import { ProjectComments } from "./tasks/ProjectComments";
import { Comment } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { InviteUsers } from "./collaborators/InviteUsers";
import { ProjectCover } from "./home/ProjectCover";

export const ProjectView = () => {
  const { project, view } = useProject();
  const form = useAsyncForm<UpdateProjectDto>({
    initialValues: project as UpdateProjectDto,
  });
  const [projectNote, setProjectNote] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);

  usePrompt(
    "You will lose your changes. Remember to save!",
    form.isDirty() && !form.isDirty("permalink")
  );

  const tabs: RouterTab[] = [
    {
      value: "home",
      label: "Comments",
      icon: <Comment className="w-5 h-5" />,
      "data-testid": "project-comments-tab",
      "data-quick-assist-id": "project-comments-tab",
      component: project ? <ProjectComments projectId={project.id} /> : null,
    },
    {
      value: "files",
      label: "Files",
      icon: <Books className="w-5 h-5" />,
      "data-testid": "project-media-library-tab",
      "data-quick-assist-id": "project-media-library-tab",
      component: <ProjectFiles />,
    },
    {
      value: "tasks",
      label: "Tasks",
      icon: <Checkbox className="w-5 h-5" />,
      "data-testid": "project-tasks-tab",
      "data-quick-assist-id": "project-tasks-tab",
      component: project ? <ProjectTasks projectId={project.id} /> : null,
    },
    {
      value: "threads",
      label: "Threads",
      icon: <Pencil2Icon className="w-5 h-5" />,
      "data-testid": "project-thread-tab",
      "data-quick-assist-id": "project-thread-tab",
      component: (
        <Routes>
          <Route
            path=":noteId"
            element={
              <ProjectNote
                onMount={() => setProjectNote(true)}
                onUnmount={() => setProjectNote(false)}
              />
            }
          />
          <Route path="create" element={<CreateNote />} />
          {project && <Route path="" element={<ProjectNotes />} />}
        </Routes>
      ),
    },
    {
      value: "collab",
      label: "Collaborators",
      icon: <UserGroupIcon className="w-5 h-5" />,
      "data-testid": "project-collaborators-tab",
      "data-quick-assist-id": "project-collaborators-tab",
      component: <ProjectCollaborators />,
    },
  ];

  return (
    <div
      className="container max-w-[128rem]"
      data-quick-assist-id="project-home"
    >
      <Helmet>
        <title>{project?.name || "Loading..."} | Synqup</title>
      </Helmet>

      <ProjectCover form={form} />

      <div className="flex flex-col md:flex-row items-end md:items-center justify-end gap-2 pb-4">
        <Link to={`/p/${project?.permalink}/viewer`}>
          <Button
            data-testid="launch-stemviewer"
            size="sm"
            variant="gradient"
            leftIcon={<Microphone className="w-5 h-5" />}
          >
            Launch StemViewer
          </Button>
        </Link>

        <div className="flex items-center gap-2">
          <ActionIcon
            data-testid="project-share-button"
            size="lg"
            onClick={() => setShareModal(true)}
          >
            <Share className="w-5 h-5" />
          </ActionIcon>

          {view === "admin" && (
            <ActionIcon
              data-testid="project-settings-button"
              size="lg"
              onClick={() => setSettingsModal(true)}
            >
              <CogIcon className="w-5 h-5" />
            </ActionIcon>
          )}
        </div>
      </div>

      <InviteUsers opened={shareModal} onClose={() => setShareModal(false)} />

      {view === "admin" && (
        <Modal
          size="lg"
          opened={settingsModal}
          onClose={() => setSettingsModal(false)}
        >
          <ProjectSettings form={form} />
        </Modal>
      )}

      <div className="flex items-start flex-col lg:flex-row gap-8">
        <ProjectHeader
          form={form}
          onAddSocials={() => setSettingsModal(true)}
        />

        <div className="flex-1 w-full">
          {project?.permalink ? (
            <>
              {!projectNote && (
                <div className="mb-4">
                  <ProjectMixdown form={form} />

                  <ProjectDescription
                    data-testid="project-description-home"
                    form={form}
                  />
                </div>
              )}

              <RouterTabs
                variant="pills"
                basePath={`/p/${project.permalink}`}
                defaultValue={"home"}
                styles={{
                  tabsList: {
                    display: !projectNote ? "flex" : "none",
                  },
                }}
                tabs={tabs}
              />
            </>
          ) : (
            <>
              <Skeleton visible width="100%" height={50} />
              <br />
              <Skeleton visible width="100%" height={100} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import {
  useDelete,
  useGet,
  useMultipartForm,
  usePost,
  useStaleSWR,
  useStickyStaleSWR,
} from "./helpers";
import { QueryFileDto } from "@server/modules/file/dto/query-file.dto";
import { File as FileEntity } from "../../../server/src/entities";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { UploadFileDto } from "@server/modules/file/dto/upload-file.dto";
import { StorageData } from "@server/modules/file/dto/storage-data";
import { ShareFilesDto } from "@server/modules/file/dto/share-files.dto";
import { AxiosRequestConfig } from "axios";

export const uploadFile = (data: UploadFileDto, config?: AxiosRequestConfig) =>
  useMultipartForm("/file", data, config);

export const useFile = (id: string, scode?: string) =>
  useStaleSWR<FileEntity>(`/file/${id}${scode ? `?scode=${scode}` : ""}`);

export const useFiles = (queryFileDto: QueryFileDto) =>
  useStickyStaleSWR<PaginatedListDto<FileEntity>>("/file", queryFileDto);

export const getFiles = (queryFileDto: QueryFileDto) =>
  useGet<PaginatedListDto<FileEntity>>("/file", queryFileDto);

export const useFileStorage = () => useStaleSWR<StorageData>("/file/storage");

export const shareFiles = (data: ShareFilesDto) =>
  usePost<string>("/file/share", data);

export const deleteFile = (id: string) => useDelete(`/file/${id}`);

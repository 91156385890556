import React from "react";
import {
  BsFileMusicFill,
  BsFilePdfFill,
  BsFileWordFill,
  BsFileZipFill,
} from "react-icons/bs";
import { File } from "@server/entities";
import { File as StandardFileIcon } from "tabler-icons-react";
import { getFiletype } from "./FileLibrary";
import { MantineSize, useMantineTheme } from "@mantine/core";

interface FileIconProps {
  file: File;
  size?: MantineSize;
}

export const FileIcon: React.FC<FileIconProps> = ({ file, size = "xs" }) => {
  const theme = useMantineTheme();
  const style = {
    width: theme.fontSizes[size] * 1.5,
    height: theme.fontSizes[size] * 1.5,
  };

  switch (getFiletype(file)) {
    case "mp3":
    case "wav":
    case "m4a":
    case "flac":
    case "ogg":
      return <BsFileMusicFill className="text-primary-500" style={style} />;
    case "pdf":
      return <BsFilePdfFill className="text-red-500" style={style} />;
    case "doc":
    case "docx":
      return <BsFileWordFill className="text-blue-500" style={style} />;
    case "zip":
    case "rar":
      return <BsFileZipFill className="text-gray-500" style={style} />;
    case "png":
    case "jpeg":
    case "jpg":
    case "gif":
    case "svg":
    case "webp":
      return (
        <img
          src={file.url}
          alt={file.filename}
          className="object-cover rounded"
          style={style}
        />
      );
    default:
      return <StandardFileIcon className="text-dark-400" style={style} />;
  }
};

import {
  QuickAssistSection,
  QuickAssistSectionKey,
} from "./QuickAssistContext";
import hidePanelImage from "../../res/images/quick-assist/hide-panel.gif";
import markupImage from "../../res/images/quick-assist/mark-up.gif";
import mixdownImage from "../../res/images/quick-assist/mixdown.gif";
import replaceImage from "../../res/images/quick-assist/replace.gif";
import resizeImage from "../../res/images/quick-assist/resize.gif";

export const quickAssists: Record<QuickAssistSectionKey, QuickAssistSection> = {
  home: {
    heading: "Home",
    value: "home",
    body: "Description TBD",
    assists: [
      {
        heading: "Recent logs",
        body: "See all of the recent activity across all of your projects.",
        value: "recent-logs",
        steps: [
          {
            target: "[data-quick-assist-id=recent-logs]",
            content:
              "Click on the activity log to take you to the project it’s related to.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Recent projects",
        body: "View all of your recent projects that you are working on.",
        value: "recent-projects",
        steps: [
          {
            target: "[data-quick-assist-id=recent-projects-0]",
            content:
              "You'll see all your recent projects by clicking this tab.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=recent-projects-1]",
            content: "Click on a project to go straight into it.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=header-projects-button]",
            content: "Click here to view all of your projects.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Assigned to me",
        body: "View all of the Mark-ups assigned to you.",
        value: "assigned-to-me",
        steps: [
          {
            target: "[data-quick-assist-id=assigned-to-me]",
            content:
              "You'll see all the Mark-ups assigned to you by clicking this tab.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=my-markups]",
            content:
              "Click on a Mark-up to go straight into the project it’s related to.",
          },
        ],
      },
      {
        heading: "Assigned by me",
        body: "View all of the Mark-ups assigned by you.",
        value: "assigned-by-me",
        steps: [
          {
            target: "[data-quick-assist-id=assigned-by-me]",
            content:
              "You'll see all the Mark-ups assigned by you by clicking this tab.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=my-markups]",
            content:
              "Click on a Mark-up to go straight into the project it’s related to.",
          },
        ],
      },
      {
        heading: "Files",
        body: "Upload, share and search through files in the Media Library.",
        value: "files",
        steps: [
          {
            target: "[data-quick-assist-id=dropzone]",
            content:
              "You can either drag and drop, or browse your computer for files to select.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=dropzone-upload]",
            content:
              "Click Upload to upload the files into your Media Library.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=ml-sort]",
            content:
              "You can sort files in your Media Library by name, date or size.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=ml-tabs]",
            content:
              "Filter your search by Audio, Stems, and Images. Click Shared to view files shared with you by other users.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=ml-storage]",
            content:
              "View how much storage you have used in total and how much storage you have left.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Task bucket",
        body: "Directly access all of the tasks assigned to you through the Task Bucket where ever you go on the site!",
        value: "task-bucket",
        steps: [
          {
            target: "[data-quick-assist-id=task-bucket]",
            content:
              "You'll see all of your assigned Mark-ups by clicking this tab.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target:
              "[data-quick-assist-id=task-bucket] .cursor-pointer:first-of-type",
            content:
              "Click on a Mark-up to go straight into the project it’s related to.",
          },
        ],
      },
      {
        heading: "Connections",
        body: "Directly message collaborators by clicking on the name or avatar. You can access all your messages by clicking on the messages icon in the top menu bar.",
        value: "connections",
        steps: [
          {
            target: "[data-quick-assist-id=connections]",
            content: "You're connections are listed here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=search-users]",
            content: "Search for users to add them as a connection.",
            disableBeacon: true,
          },
          {
            target:
              "[data-quick-assist-id=connections] .cursor-pointer:first-of-type",
            content: "Click on a connection to message them.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=messages-menu]",
            content: "Click here to view all of your messages.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Hide Side Panel",
        body: "You can hide the right sidebar to maximise space.",
        value: "hide-side-panel",
        image: hidePanelImage,
        steps: [
          {
            target: "[data-quick-assist-id=hide-side-panel]",
            content: "Hover your mouse here to reveal the hide/show button",
            disableBeacon: true,
          },
        ],
      },
    ],
  },
  projects: {
    heading: "Projects",
    value: "projects",
    body: "Description TBD",
    assists: [
      {
        heading: "Create a new project",
        body: "Create a new project and invite collaborators to get started!",
        value: "create-project",
        steps: [
          {
            target: "[data-quick-assist-id=create-project]",
            content: "Click here to create a new project.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Filter projects",
        body: "Filter for projects that your created and projects that you were invited to for a more refined experience.",
        value: "filter-project",
        steps: [
          {
            target: "[data-quick-assist-id=filter-project]",
            content: "Click here to filter your projects.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Sort projects",
        body: "Sort projects by name, create and last opened dates.",
        value: "sort",
        steps: [
          {
            target: "[data-quick-assist-id=sort-project]",
            content: "Click here to sort your projects.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Favourite projects",
        body: "Easily access your important projects.",
        value: "favourite-projects",
        steps: [
          {
            target: "[data-quick-assist-id=star-project]",
            content: "Click on the star to favourite a project.",
            disableBeacon: true,
          },
        ],
      },
    ],
  },
  project: {
    heading: "Project page",
    value: "project",
    body: "Description TBD",
    assists: [
      {
        heading: "Upload or generate a mixdown",
        body: "Show your collaborators what you have been working on with a mixdown of your current draft.",
        value: "mixdown-upload-generated",
        image: mixdownImage,
        steps: [
          {
            target: "[data-quick-assist-id=mixdown-upload]",
            content: "Click here to upload a mixdown of your current draft.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=mixdown-generate]",
            content:
              "Click here to generate a mixdown, based on what is currently on the Stem Viewer. It should take a few minutes to generate.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=mixdown-switch]",
            content: "Click here to switch between the two mixdowns.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Add a description",
        body: "Tell your audience more about what your project is about by adding a description.",
        value: "project-description",
        steps: [
          {
            target: "[data-quick-assist-id=project-description]",
            content: "Click here to add a description.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Start a thread",
        body: "Start a thread with your collaborators to discuss the details and specifics of your project. You can also add threads from inside the Stem Viewer.",
        value: "project-threads",
        steps: [
          {
            target: "[data-quick-assist-id=project-thread-tab]",
            content: "Click here to open the threads tab.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=project-thread-create]",
            content: "Click here to start a new thread.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "View your Project Media Library",
        body: "View all your project files in the project Media Library. You can also upload files directly, sort and filter by file type.",
        value: "project-media-library",
        steps: [
          {
            target: "[data-quick-assist-id=project-media-library-tab]",
            content: "Click here to view your project Media Library.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Invite collaborators",
        body: "Invite new users to your project and update the roles and permissions of existing collaborators.",
        value: "project-collaborators",
        steps: [
          {
            target: "[data-quick-assist-id=project-collaborators-tab]",
            content: "Click here to view your project collaborators.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=project-invite-user]",
            content: "Click here to invite a new user to the project.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=invite-role]",
            content:
              "Click here to select the role of a collaborator. Viewers can only view the project description and the mixdown. Editors can work in the stemviewer, create threads and upload files. Admins can do everything.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=invite-search-user]",
            content:
              "Search for a user to invite to the project. If the user is not on Synqup you can invite them by email address",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Leave project",
        body: "Leaving the project will remove you from the project and you will no longer be able to access it. You can only leave a project if you are not the owner.",
        value: "leave-project",
        steps: [
          {
            target: "[data-quick-assist-id=leave-project]",
            content: "Click here to leave the project.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Copy permalink",
        body: "Click here to copy a link to this project to share.",
        value: "copy-permalink",
        steps: [
          {
            target: "[data-quick-assist-id=copy-permalink]",
            content: "Click here to copy a link to this project to share.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Launch Stem Viewer",
        body: "Click here to launch the Stem Viewer.",
        value: "launch-stem-viewer",
        steps: [
          {
            target: "[data-quick-assist-id=launch-stemviewer]",
            content: "Click here to launch the Stem Viewer.",
            disableBeacon: true,
          },
        ],
      },
    ],
  },
  stemviewer: {
    heading: "Stem Viewer",
    value: "stemviewer",
    body: "Description TBD",
    assists: [
      {
        heading: "Controls",
        body: "Play, pause and rewind to the beginning using the Control Panel",
        value: "stemviewer-controls",
        steps: [
          {
            target: "[data-quick-assist-id=sv-stop]",
            content:
              "Stop the track and return to the beginning of the track by clicking here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-play-pause]",
            content: "Play and pause the track by clicking here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-needle]",
            content:
              "Scrub through the track by clicking and dragging the needle.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-track-header]",
            content:
              "Or click and drag the track header to scrub through the track.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Upload",
        body: "Click here to Upload tracks into the Stem Viewer session",
        value: "stemviewer-upload",
        steps: [
          {
            target: "[data-quick-assist-id=sv-upload]",
            content: "Click here to open the upload modal.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=dropzone]",
            content:
              "Drag and drop your files here or click here to browse your computer for files to select.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=dropzone-upload]",
            content: "Click here to upload your files.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Track Panels",
        body: "Mute and Solo tracks by clicking on the M and S buttons, as well as adjust volume levels for review.",
        value: "stemviewer-track-panels",
        steps: [
          {
            target: "[data-quick-assist-id=sv-stem-name]:first-of-type",
            content: "Rename the track here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-stem-color]:first-of-type",
            content:
              "Change the colour of the track by clicking on your avatar.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-mute]:first-of-type",
            content: "Mute a track by clicking here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-solo]",
            content: "Solo a track by clicking here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-volume]",
            content: "Adjust the volume of a track by clicking and dragging.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Replace",
        body: "To replace a stem, right-click on a track and click Replace. You can also replace a stem inside the Stem Viewer by clicking Upload.",
        value: "stemviewer-replace",
        image: replaceImage,
        steps: [
          {
            target: "[data-quick-assist-id=sv-stem]:first-of-type",
            content: "Right-click on a track and click Replace.",
            placement: "bottom-start",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-stem]:first-of-type",
            content: `When you replace a stem, all markups on the stem will be moved to the "Needs Approval" status.`,
            placement: "bottom-start",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Export Tracks",
        body: "Select the tracks you wish to export to begin working on them natively.",
        value: "stemviewer-export-tracks",
        steps: [
          {
            target: "[data-quick-assist-id=sv-export]",
            content: "Click here to export multiple tracks.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-stem]:first-of-type",
            content: "Or, right-click on a specific track and click Export.",
            placement: "bottom-start",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Export Mixdown",
        body: "Click here to export a mix down of the Stem Viewer tracks.",
        value: "stemviewer-export-mixdown",
        steps: [
          {
            target: "[data-quick-assist-id=sv-export-mixdown]",
            content:
              "Click here to export a mix down of the Stem Viewer tracks.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Threads",
        body: "Add threads to the Stem Viewer to save your ideas or share them with your collaborators.",
        value: "stemviewer-threads",
        steps: [
          {
            target: "[data-quick-assist-id=sv-threads]",
            content: "Click here to open the notes panel.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=sv-threads-type]",
            content:
              "Click here to switch between project and track notes. Track notes are only attached to a specific track, while project notes are attached to the project.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-threads-body]",
            content: "Add your notes here. Then click save.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=track-body]",
            content:
              "You can switch the track that you want to write notes on by selecting the track here.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=note-card]",
            content:
              "Click on a note to expand it, or double-click to view the full thread",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Mark-ups",
        body: "Create Mark-ups to leave feedback and collaborate with your team.",
        value: "markup-create",
        steps: [
          {
            target: "[data-quick-assist-id=sv-stem]:first-of-type",
            content: (
              <>
                Click and drag along the track to select a region.
                <img className="w-full mt-4" src={markupImage} alt="Create" />
              </>
            ),
            placement: "bottom-start",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-create-markup]",
            content:
              "Click this button to create a markup, or press Ctrl+M (Windows) or Cmd+M (Mac).",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-resize]",
            content: (
              <>
                Click and drag the edges of the markup to resize it.
                <img className="w-full mt-4" src={resizeImage} alt="Resize" />
              </>
            ),
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-bubble]",
            content:
              "Click on a markup to edit its details. Hit Next after the markup is open to continue the tour.",
            disableBeacon: true,
            hideCloseButton: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-title]",
            content: "Rename the markup.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=markup-status]",
            content: "Click here to change the status of the markup.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-description]",
            content: "Add a description to the markup.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-assignee]",
            content: "Assign the markup to a collaborator.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-reporter]",
            content: "Change the reporter of the markup.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-due-date]",
            content:
              "Set a due date for the markup. The assignee will be notified in the beginning of the week for the markup and again on the day of the due date.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-track]",
            content: "Change which track the markup is on.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-comments]",
            content: "View and reply to comments on the markup.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-logs]",
            content: "View the activity log for the markup.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Approval process",
        body: "Learn more about the approval process for markups.",
        value: "markup-approval-process",
        steps: [
          {
            target: "[data-quick-assist-id=sv-markup-bubble]",
            content: "Open a markup to continue the tour.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-assignee]",
            content: "Assign the markup to yourself.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=markup-status]",
            content: `Set the markup status to "Pending" before continuing the tour. This is the initial status for markups.`,
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-markup-upload]",
            content: `The assignee can upload a new version of the stem by clicking this button. This will change the status of the markup to "Needs Approval".`,
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=markup-status]",
            content: `Set the markup status to "Needs Approval" before continuing the tour.`,
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-reporter]",
            content: "Set the reporter of the markup to yourself.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-request-changes]",
            content:
              "If the reporter isn't satisfied with the new stem, they can request changes here. That will move the markup back to the Pending status.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-approve]",
            content:
              "Otherwise, they can approve it by clicking the Approve button.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Zoom",
        body: "Use the Zoom In and Out buttons to zoom in and out of the Stem Viewer tracks.",
        value: "stemviewer-zoom",
        steps: [
          {
            target: "[data-quick-assist-id=sv-zoom-in]",
            content: "Click here to zoom in.",
            disableBeacon: true,
          },
          {
            target: "[data-quick-assist-id=sv-zoom-out]",
            content: "Click here to zoom out.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Invite User",
        body: "Click here to invite a new user to the project. To invite a new user to Synqup, invite by email.",
        value: "stemviewer-invite-user",
        steps: [
          {
            target: "[data-quick-assist-id=sv-invite-user]",
            content: "Click here to invite a new user to the project.",
            disableBeacon: true,
          },
        ],
      },
      {
        heading: "Hide Side Panel",
        body: "You can the right side panel to maximise space.",
        value: "hide-side-panel",
        steps: [
          {
            target: "[data-quick-assist-id=hide-side-panel]",
            content: "Hover your mouse here to reveal the hide/show button",
            disableBeacon: true,
          },
        ],
      },
    ],
  },
};

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Divider,
  DotsMenu,
  Input,
  Select,
  Tooltip,
} from "@common/components";
import { Loader, Popover, Progress, useMantineTheme } from "@mantine/core";
import { File as FileEntity } from "@server/entities";
import Dropzone from "@common/components/Dropzone";
import { useFileStorage } from "../../requests/file";
import { File, Filter, Search, Share, Trash } from "tabler-icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import { useMediaLibrary } from "./MediaLibraryContext";
import { Download, Sort } from "@mui/icons-material";
import { downloadBlob } from "../../modules/stemviewer/helpers/download-blob";
import dayjs from "dayjs";
import JSZip from "jszip";
import axios from "axios";
import { formatBytes } from "@common/utils/constants";
import { QuestionMarkCircledIcon } from "@modulz/radix-icons";
import useIsInViewport from "use-is-in-viewport";
import { FileLibrary } from "./components/FileLibrary";

interface MediaLibraryProps {
  projectId?: string;
  queryable?: boolean;
  onFileSelect?: (file: FileEntity) => void;
}

export const MediaLibrary: React.FC<MediaLibraryProps> = ({
  projectId,
  queryable,
  onFileSelect,
  ...props
}) => {
  const theme = useMantineTheme();
  const {
    files,
    mutate,
    loading,
    query,
    setQuery,
    checked,
    onBulkDelete,
    onFilesShare,
    uploadFiles,
    handleNextPage,
  } = useMediaLibrary();
  const [uploading, setUploading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 200);
  const [isInViewport, targetRef] = useIsInViewport();

  const { data: storage, mutate: mutateStorage } = useFileStorage();

  useEffect(() => {
    setQuery((query) => ({
      ...query,
      query: debouncedSearch,
      projectId,
    }));
  }, [debouncedSearch]);

  useEffect(() => {
    if (isInViewport) {
      handleNextPage();
    }
  }, [isInViewport, files]);

  const handleUpload = async (files: File[]) => {
    setUploading(true);

    await uploadFiles(
      files.map((file) => ({ file, filetype: query.type || "file", projectId }))
    );

    mutate && (await mutate());
    await mutateStorage();
    setUploading(false);
  };

  const handleChangeType = (type?: FileEntity["type"]) => {
    if (!queryable) return;
    setQuery((query) => ({ ...query, type, page: 1, sharedWithMe: undefined }));
  };

  const handleShared = () => {
    if (!queryable) return;
    setQuery((query) => ({
      ...query,
      page: 1,
      type: undefined,
      sharedWithMe: true,
    }));
  };

  const handleDownloadSelected = async () => {
    if (!files?.data) return;
    const zip = new JSZip();

    setDownloadProgress(0);

    if (checked.length === 0) return;
    if (checked.length === 1) {
      const { data } = await axios.get(checked[0].url, {
        responseType: "blob",
      });
      downloadBlob(data, checked[0].filename);
      return;
    }

    for (const [idx, file] of checked.entries()) {
      try {
        const { data } = await axios.get(file.url, {
          responseType: "blob",
          onDownloadProgress: (e) => {
            setDownloadProgress(
              (e.loaded / e.total + idx) * (100 / checked.length)
            );
          },
        });
        zip.file(file.filename, data);
      } catch (e) {
        console.error(e);
      }
    }
    setDownloadProgress(100);

    return new Promise<void>((resolve) => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        downloadBlob(content, `${dayjs().format("YYYYMMDDHHmm")}-files.zip`);
        resolve();
      });
    });
  };

  const filterOptions = [
    {
      label: "All files",
      value: undefined,
      selected: !query.type && !query.sharedWithMe,
      onClick: () => handleChangeType(undefined),
    },
    {
      label: "Mixdowns",
      value: "mixdown",
      selected: query.type === "mixdown",
      onClick: () => handleChangeType("mixdown"),
    },
    {
      label: "Audio",
      value: "audio",
      selected: query.type === "audio",
      onClick: () => handleChangeType("audio"),
    },
    {
      label: "Stems",
      value: "stem",
      selected: query.type === "stem",
      onClick: () => handleChangeType("stem"),
    },
    {
      label: "Images",
      value: "image",
      selected: query.type === "image",
      onClick: () => handleChangeType("image"),
    },
    {
      label: "Shared",
      value: true,
      selected: query.sharedWithMe,
      onClick: handleShared,
    },
  ];

  let acceptedFileTypes: string[] | undefined = undefined;
  if (query.type === "audio") acceptedFileTypes = ["audio/*"];
  if (query.type === "image") acceptedFileTypes = ["image/*"];

  return (
    <div data-testid="media-library" className="flex flex-col gap-6" {...props}>
      <div className="flex items-center gap-4">
        <Input
          className="flex-1"
          icon={loading ? <Loader size="xs" /> : <Search className="w-5" />}
          placeholder="Search files"
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
        />

        <Popover radius="xs" position="bottom-end">
          <Popover.Target>
            <Button
              variant="filled"
              color={query.type || query.sharedWithMe ? "primary" : "dark"}
              leftIcon={<Filter className="w-4" />}
              data-testid="filter-button"
              data-quick-assist-id="ml-filter"
            >
              Filter
            </Button>
          </Popover.Target>

          <Popover.Dropdown p="xs">
            <div className="flex flex-col gap-2">
              {filterOptions.map((option) => (
                <Button
                  data-testid={`filter-${option.label}`}
                  className="text-left"
                  key={option.label}
                  onClick={option.onClick}
                  variant={option.selected ? "filled" : "subtle"}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </Popover.Dropdown>
        </Popover>

        <Popover radius="xs" position="bottom-end">
          <Popover.Target>
            <Button
              variant="filled"
              color={query.sortBy && query.sortDirection ? "primary" : "dark"}
              leftIcon={<Sort className="w-4" />}
              data-quick-assist-id="ml-sort"
            >
              Sort
            </Button>
          </Popover.Target>

          <Popover.Dropdown p="xs">
            <div className="flex gap-2">
              <Select
                className="w-24"
                size="sm"
                data={[
                  { label: "Name", value: "filename" },
                  { label: "Date", value: "createdAt" },
                  { label: "Size", value: "size" },
                ]}
                value={query.sortBy}
                onChange={(value) =>
                  setQuery((query) => ({
                    ...query,
                    sortBy: value || undefined,
                  }))
                }
              />

              <Select
                className="w-36"
                size="sm"
                data={[
                  { label: "Ascending", value: "ASC" },
                  { label: "Descending", value: "DESC" },
                ]}
                value={query.sortDirection}
                onChange={(value) =>
                  setQuery((query) => ({
                    ...query,
                    sortDirection: value || undefined,
                  }))
                }
              />
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>

      <div className="flex-1 min-w-0">
        {storage && !projectId && (
          <div className="flex flex-row-reverse items-center gap-4 mb-4">
            <div className="flex items-center gap-2 text-center mb-4">
              <div>
                <h4>Storage used</h4>
                <p className="text-xs text-gray-500 -mb-4">
                  {formatBytes(
                    storage.stem + storage.audio + storage.image + storage.file,
                    1
                  )}{" "}
                  / {formatBytes(storage.total, 0)}
                </p>
              </div>

              <Tooltip
                label={
                  "This amount is not live and may take a few minutes to update."
                }
              >
                <QuestionMarkCircledIcon className="text-gray-500" />
              </Tooltip>
            </div>

            <Progress
              data-quick-assist-id="ml-storage"
              className="flex-1"
              classNames={{
                label: "text-xs font-medium",
              }}
              size={24}
              sections={[
                {
                  value: (storage.image / storage.total) * 100,
                  color: "cyan",
                  tooltip: `Images - ${formatBytes(storage.image)}`,
                  label:
                    storage.image / storage.total > 0.05 ? "Images" : undefined,
                },
                {
                  value: (storage.audio / storage.total) * 100,
                  color: "blue",
                  tooltip: `Audio - ${formatBytes(storage.audio)}`,
                  label:
                    storage.audio / storage.total > 0.05 ? "Audio" : undefined,
                },
                {
                  value: (storage.stem / storage.total) * 100,
                  color: "indigo",
                  tooltip: `Stems - ${formatBytes(storage.stem)}`,
                  label:
                    storage.stem / storage.total > 0.05 ? "Stems" : undefined,
                },
                {
                  value: (storage.file / storage.total) * 100,
                  color: "violet",
                  tooltip: `Other - ${formatBytes(storage.file)}`,
                  label:
                    storage.file / storage.total > 0.05 ? "Other" : undefined,
                },
              ]}
            />
          </div>
        )}
        <Dropzone
          loading={uploading}
          onUpload={handleUpload}
          acceptedFileTypes={acceptedFileTypes}
        />
        {downloadProgress !== 0 && (
          <Progress
            size="xs"
            color={theme.colors.indigo[7]}
            value={downloadProgress}
            className="mt-4"
          />
        )}
        <div className="flex items-center gap-4 relative">
          {files && checked.length > 0 && (
            <div className="absolute z-50 top-5 bg-dark-900 px-4 rounded shadow-xl flex items-center animate-opacity">
              <p data-testid="files-selected" className="m-0 mr-4">
                {checked.length} selected
              </p>

              <Divider orientation="vertical" className="h-10" />

              <ActionIcon
                loading={downloadProgress !== 0 && downloadProgress !== 100}
                variant="subtle"
                color="red"
                onClick={onBulkDelete}
              >
                <Trash className="w-4 h-4" />
              </ActionIcon>

              <Divider orientation="vertical" className="h-10" />

              <DotsMenu data-testid="selected-dots-menu" className="-mr-3">
                <DotsMenu.Dropdown>
                  <DotsMenu.Item
                    data-testid="download-selected"
                    onClick={handleDownloadSelected}
                    icon={<Download className="w-4 h-4" />}
                  >
                    Download
                  </DotsMenu.Item>
                  <DotsMenu.Item
                    data-testid="share-selected"
                    onClick={() => onFilesShare(checked)}
                    icon={<Share className="w-4 h-4" />}
                  >
                    Share
                  </DotsMenu.Item>
                </DotsMenu.Dropdown>
              </DotsMenu>
            </div>
          )}
        </div>

        <div className="w-full mt-4">
          <FileLibrary
            files={files?.data}
            onFileSelect={onFileSelect}
            projectId={projectId}
            sharedWithMe={query.sharedWithMe}
          />
        </div>
        {files && <div ref={targetRef} className="w-full h-2" />}
      </div>
    </div>
  );
};

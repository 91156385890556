import React, { useEffect } from "react";
import { File } from "@server/entities";
import { Table } from "@mantine/core";
import { FileHeader, FileRow } from "./FileRow";
import { useMediaLibrary } from "../MediaLibraryContext";
import { groupBy } from "lodash";
import dayjs from "dayjs";
import { Divider } from "@common/components";

interface FileLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
  projectId?: string;
  sharedWithMe?: boolean;
}

export const getFilename = (file: File) =>
  file.filename.split(".").slice(0, -1).join(".");
export const getFiletype = (file: File) =>
  file.filename.split(".").slice(-1)[0];

export const FileLibrary: React.FC<FileLibraryProps> = ({
  files,
  onFileSelect,
  projectId,
  sharedWithMe,
}) => {
  const { setChecked } = useMediaLibrary();

  // Clear checked files on unmount
  useEffect(() => {
    return () => setChecked([]);
  }, []);

  const getTimeDifference = (file: File) => {
    const createdAt = dayjs(file.createdAt).startOf("day");
    const diff = dayjs().diff(createdAt, "day");
    if (diff > 30) return dayjs(file.createdAt).format("MMM YYYY");
    if (diff > 1) return `${diff} days ago`;
    if (dayjs().isAfter(createdAt, "day")) return "Yesterday";
    if (dayjs().isSame(createdAt, "day")) return "Today";
    return "Just now";
  };

  const groupedFiles = groupBy(files, getTimeDifference);

  return (
    <Table className="overflow-visible -ml-[1rem] w-[calc(100%+1rem)]  md:-ml-[3rem] md:w-[calc(100%+3rem)]">
      <thead>
        <FileHeader
          files={files}
          projectId={projectId}
          sharedWithMe={sharedWithMe}
        />
      </thead>

      <tbody>
        {files?.length === 0 && (
          <tr>
            <td colSpan={7}>
              <p className="text-center text-dark-400 m-0 py-4">
                No files found
              </p>
            </td>
          </tr>
        )}

        {Object.keys(groupedFiles).map((date) => (
          <React.Fragment key={date}>
            <tr>
              <td className="border-none" />
              <td className="border-dark-800 pl-0" colSpan={6}>
                <div className="flex items-center gap-2 mb-2 mt-3">
                  <p className="text-gray-200 m-0">{date}</p>
                  <Divider className="flex-1 border-primary-500 bg-primary-700" />
                </div>
              </td>
            </tr>

            {groupedFiles[date].map((file) => (
              <FileRow
                key={file.id}
                file={file}
                projectId={projectId}
                onFileSelect={onFileSelect}
                sharedWithMe={sharedWithMe}
              />
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { deleteFile, useFile } from "../../../requests/file";
import { Skeleton } from "@mantine/core";
import {
  getFilename,
  getFiletype,
} from "../../../contexts/MediaLibrary/components/FileLibrary";
import { FileIcon } from "../../../contexts/MediaLibrary/components/FileIcon";
import { Avatar, AvatarsGroup, Button } from "@common/components";
import { FileMenu } from "../../../contexts/MediaLibrary/components/FileMenu";
import { downloadUrl } from "../../stemviewer/helpers/download-blob";
import { useMediaLibrary } from "../../../contexts/MediaLibrary/MediaLibraryContext";
import Confirm from "@common/components/Confirm";
import { Trash } from "tabler-icons-react";
import { notification } from "@common/utils/notification";
import { errorNavigator } from "@common/utils/error-navigator";
import { File } from "@server/entities";
import { PlayableAudioWaveform } from "@common/components/PlayableAudioWaveform";
import { usePlayer, usePlayerState } from "../../../contexts/Player";
import dayjs from "dayjs";
import { FileComments } from "../components/FileComments";
import { StringParam, useQueryParams } from "use-query-params";

interface FilePageProps {
  fileId: string;
  scode?: string;
}

export const isAudioFile = (file: File) => {
  switch (getFiletype(file)) {
    case "mp3":
    case "wav":
    case "flac":
    case "ogg":
    case "m4a":
      return true;
    default:
      return false;
  }
};

const FilePageComponent: React.FC<FilePageProps> = ({ fileId, scode }) => {
  const { onFilesShare } = useMediaLibrary();
  const { setVisible } = usePlayer();
  const { audio } = usePlayerState(["audio"]);
  const [downloading, setDownloading] = useState(false);
  const [del, setDel] = useState(false);

  const {
    data: file,
    isValidating: loading,
    error,
    mutate,
  } = useFile(fileId, scode);

  // Hide player if playing the mixdown
  useEffect(() => {
    if (audio?.fileId === file?.id) {
      setVisible(false);
    }

    return () => {
      if (audio) setVisible(true);
    };
  }, [audio]);

  const handleDownload = async () => {
    if (!file) return;

    setDownloading(true);
    await downloadUrl(file.url, file.filename);
    setDownloading(false);
  };

  const handleShare = async () => {
    if (!file) return;
    onFilesShare([file]);
    await mutate();
  };

  const handleFileDelete = async () => {
    if (!file) return;

    const { error } = await deleteFile(file.id);
    if (error) return notification.error(error.message);
    setDel(false);
  };

  if (error) return errorNavigator(error);

  return (
    <div className="container" data-testid="file-page">
      <Skeleton visible={loading} className="overflow-visible">
        <div className="flex justify-between gap-16">
          <div className="flex-1 flex items-center gap-6 w-0">
            {file && <FileIcon size="xl" file={file} />}
            <h2
              data-testid="file-name"
              className="text-5xl text-wrap break-words w-full"
            >
              {file ? getFilename(file) : "..."}
            </h2>
          </div>

          <div className="flex items-center gap-2">
            <Button
              data-testid="download-file"
              size="sm"
              onClick={handleDownload}
              loading={downloading}
            >
              Download
            </Button>

            <Button
              data-testid="share-file"
              size="sm"
              variant="light"
              color="gray"
              onClick={handleShare}
            >
              Share
            </Button>

            {file && <FileMenu file={file} onFileDelete={() => setDel(true)} />}
          </div>
        </div>
      </Skeleton>

      <div className="flex items-center justify-between gap-4 mt-6 mb-8">
        <div className="flex items-center gap-4">
          <Avatar size="sm" user={file?.user} />
          {file ? (
            <p data-testid="file-user" className="text-md font-medium">
              {file.user.displayName}
              <span className="text-dark-400 ml-2 font-normal">
                uploaded {dayjs(file?.createdAt).fromNow()}
              </span>
            </p>
          ) : (
            <Skeleton visible width={96} height={16} />
          )}
        </div>

        <div className="flex items-center gap-4">
          {file && file.sharedWith.length > 0 && (
            <>
              <span className="text-dark-400">Who can access:</span>
              <AvatarsGroup
                data-testid="file-shared-with"
                users={file?.sharedWith}
              />
            </>
          )}
        </div>
      </div>

      {file && isAudioFile(file) && (
        <div className="my-6">
          <PlayableAudioWaveform
            file={file}
            isPlaying={audio?.fileId === file.id}
          />
        </div>
      )}

      {file ? (
        <FileComments
          file={file}
          isPlaying={audio?.fileId === file.id}
          mutate={mutate}
        />
      ) : (
        <Skeleton visible height={120} />
      )}

      <Confirm
        icon={<Trash />}
        color="red"
        title="Delete file"
        content={`Are you sure you want to delete ${file?.filename}?`}
        opened={del}
        onClose={() => setDel(false)}
        onConfirm={handleFileDelete}
      />
    </div>
  );
};

export const FilePage: React.FC = () => {
  const [query] = useQueryParams({
    scode: StringParam,
  });
  const { fileId } = useParams<{ fileId: string }>();
  if (!fileId) {
    return null;
  }
  return <FilePageComponent fileId={fileId} scode={query.scode || undefined} />;
};

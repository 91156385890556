import { MantineTheme, MantineThemeColors } from "@mantine/core";
import resolveConfig from "tailwindcss/resolveConfig";
import config from "../tailwind.config";
const tailwindConfig = resolveConfig(config);

const tailwindColorMapping = (
  config: typeof tailwindConfig
): DeepPartial<MantineThemeColors> => {
  const keys = Object.keys(config.theme.accentColor);
  const colors = {};
  keys.forEach((key) => {
    // @ts-ignore
    if ([...Object.values(config.theme.accentColor[key])][0].length === 7)
      // @ts-ignore
      colors[key] = [...Object.values(config.theme.accentColor[key])];
  });
  return colors;
};

export const theme: MantineTheme = {
  fontFamily: tailwindConfig.theme.fontFamily.sans.join(", "),
  colorScheme: "dark",
  defaultRadius: "sm",
  // @ts-ignore
  colors: tailwindColorMapping(tailwindConfig),
  primaryColor: tailwindConfig.theme.primaryColor,
  primaryShade: 7,
  // @ts-ignore
  shadows: {
    md: "5px 5px 10px rgba(0,0,0,.15)",
    lg: "10px 10px 16px rgba(0,0,0,.15)",
    xl: "16px 16px 32px rgba(0,0,0,.15)",
  },
};

export const defaultProps = {
  Input: { variant: "filled" },
  Table: {},
};

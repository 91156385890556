import React, { useState } from "react";
import Skeleton from "@common/components/Skeleton";
import { Avatar, Table } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import { Markup } from "@server/entities";
import { updateMarkup } from "../../../requests/project/markup";
import { notification } from "@common/utils/notification";
import dayjs from "dayjs";
import Checkbox from "@common/components/Checkbox";
import { DatePicker, Tooltip } from "@common/components";
import { useNavigate } from "react-router-dom";

interface TasksTableProps {
  tasks?: Markup[];
  mutate: () => void;
}

export const TasksTable: React.FC<TasksTableProps> = ({ tasks, mutate }) => {
  const timeout = React.useRef<NodeJS.Timeout>();

  const handleUpdate = () => {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      mutate();
    }, 1500);
  };

  if (!tasks) {
    return (
      <div className="mt-4">
        {Array.from({ length: 3 }).map((_, i) => (
          <Skeleton className="mb-2" key={i} visible height={40} />
        ))}
      </div>
    );
  }

  if (tasks.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 h-48">
        🎉
        <span className="w-full text-gray-500 text-center">
          You&apos;re all caught up!
        </span>
      </div>
    );
  }

  return (
    <Table className="divide-x-0 divide-y divide-solid divide-dark-700 mt-2">
      <thead>
        <tr>
          <th />
          <th className="w-full">Task</th>
          <th align="center">Project</th>
          <th align="center">Due date</th>
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>
          {tasks.map((markup) => (
            <TaskRow key={markup.id} markup={markup} onUpdate={handleUpdate} />
          ))}
        </AnimatePresence>
      </tbody>
    </Table>
  );
};

const TaskRow: React.FC<{
  markup: Markup;
  onUpdate: () => void;
}> = ({ markup, onUpdate }) => {
  const navigate = useNavigate();
  const [dueDate, setDueDate] = useState<Date | null>(
    markup.dueDate ? new Date(markup.dueDate) : null
  );
  const [checked, setChecked] = useState(false);

  const handleCheck = async () => {
    if (!markup) return;
    setChecked((prev) => !prev);
    const { error } = await updateMarkup(markup.id, {
      status: checked ? "pending" : "approved",
    });
    if (error) return notification.error(error.message);
    onUpdate();
  };

  const handleOpen = () => {
    if (!markup) return;
    navigate(`?markupId=${markup.id}`);
  };

  const handleDateChange = async (date: Date | null) => {
    if (!markup) return;
    setDueDate(date);
    const { error } = await updateMarkup(markup.id, {
      dueDate: dayjs(date).toDate(),
    });
    if (error) return notification.error(error.message);
    onUpdate();
  };

  const isOverdue = dueDate && dayjs(dueDate).isBefore(dayjs(), "day");

  if (!markup) return null;

  return (
    <tr className="cursor-pointer hover:bg-dark-900 transition">
      <td>
        <Checkbox
          styles={{ label: { padding: 0 } }}
          checked={checked}
          size="sm"
          onClick={handleCheck}
        />
      </td>
      <td onClick={handleOpen}>
        <p
          style={{
            opacity: checked ? 0.5 : 1,
            textDecoration: checked ? "line-through" : "none",
          }}
          className="text-sm text-dark-200 p-0"
        >
          {markup.title}
        </p>
      </td>
      <td align="center" onClick={handleOpen}>
        <Tooltip label={markup.project?.name}>
          <Avatar src={markup.project?.albumArt} size="sm" />
        </Tooltip>
      </td>
      <td style={{ padding: 0 }} align="center">
        <DatePicker
          name="dueDate"
          size="sm"
          inputFormat="D/M/YY"
          value={dueDate}
          onChange={handleDateChange}
          preventFocus
          classNames={{
            input: `w-24 border-0 ${
              isOverdue
                ? "bg-red-500 bg-opacity-20 hover:bg-opacity-40"
                : "bg-transparent hover:bg-dark-700"
            } px-3`,
            rightSection: "opacity-0 hover:opacity-100",
          }}
        />
      </td>
    </tr>
  );
};

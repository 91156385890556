import React from "react";
import { useTopMixdowns } from "../../../requests/project/mixdown";
import { Mixdown } from "@server/entities";
import { Avatar, useMantineTheme } from "@mantine/core";
import { ActionIcon, AvatarsGroup } from "@common/components";
import { PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { usePlayer, usePlayerState } from "../../../contexts/Player";
import { useNavigate } from "react-router-dom";

export const RecentPublicProjects: React.FC = () => {
  const { data } = useTopMixdowns();

  if (!data) return null;

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="animate-opacity lg:p-8">
      <h2 className="text-3xl mb-6">Top projects</h2>

      <div className="flex flex-col bg-dark-800 rounded overflow-hidden divide-x-0 divide-y divide-solid divide-dark-600">
        {data.map((mixdown, index) => (
          <MixdownRow key={mixdown.id} index={index} mixdown={mixdown} />
        ))}
      </div>
    </div>
  );
};

const MixdownRow: React.FC<{
  index: number;
  mixdown: Mixdown & { listenCount: number };
}> = ({ index, mixdown }) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { load } = usePlayer();
  const { playing, audio } = usePlayerState(["playing", "audio"]);
  const project = mixdown.project;
  const top = index === 0;

  if (!project) return null;

  const toColor = theme.fn.rgba(
    project.albumArtColor || theme.colors.purple[8],
    0.1
  );
  const fromColor = theme.fn.rgba(
    project.albumArtColor || theme.colors.purple[8],
    0.5
  );

  const handlePlay = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!mixdown.file) return;
    load({
      src: mixdown.file?.url,
      name: project.name,
      artist: project.artist,
      artwork: project.albumArt,
      projectId: project.id,
      mixdownId: mixdown.id,
    });
  };

  const handleNavigate = () => {
    navigate(`/p/${project.permalink}`);
  };

  return (
    <div
      className="flex items-center justify-between gap-4 px-4 py-3 cursor-pointer transition hover:bg-dark-900"
      style={{
        background: top
          ? `linear-gradient(125deg, ${fromColor}, ${toColor})`
          : "unset",
        // @ts-ignore
      }}
      onClick={handleNavigate}
    >
      <div className="flex items-center gap-4">
        <span
          className="font-semibold text-purple-200 mix-blend-luminosity"
          style={{
            fontSize: top ? 18 : 14,
          }}
        >
          #{index + 1}
        </span>

        <div className="flex items-center gap-4 py-1">
          <Avatar size={top ? "lg" : "md"} radius="sm" src={project.albumArt} />

          <div>
            <h3
              data-testid="project-card-title"
              className="text-md font-medium my-0 py-0 whitespace-pre-wrap line-clamp-1"
              style={{ fontSize: top ? 20 : 16 }}
            >
              {project.name}
            </h3>
            <span
              className="text-purple-200 mix-blend-luminosity"
              style={{ fontSize: top ? 14 : 12 }}
            >
              {project.artist}
            </span>
          </div>
        </div>

        <div className="hidden md:block">
          <AvatarsGroup
            noAnimation
            size={top ? "md" : "sm"}
            className="ml-auto"
            users={project.collaborators.map((collab) => collab?.user)}
          />
        </div>
      </div>

      <div className="flex items-center gap-4">
        <ActionIcon
          size={top ? "lg" : "md"}
          radius="xl"
          variant={top ? "gradient" : "light"}
          onClick={handlePlay}
        >
          {playing && audio?.mixdownId === mixdown.id ? (
            <PauseRounded />
          ) : (
            <PlayArrowRounded />
          )}
        </ActionIcon>
      </div>
    </div>
  );
};

import React from "react";
import { MediaLibrary } from "../../../contexts/MediaLibrary/MediaLibrary";
import { withAuth } from "../../routes/ProtectedRoute";

export const Files: React.FC = withAuth(() => {
  return (
    <div className="container p-8 px-12">
      <h2 className="text-4xl my-6">Files</h2>
      <MediaLibrary queryable={true} />
    </div>
  );
});

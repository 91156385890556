import React from "react";
import { RecentLogs } from "../components/RecentLogs";
import { Helmet } from "react-helmet";
import { RecentProjects } from "../components/RecentProjects";
import { withAuth } from "../../routes/ProtectedRoute";
import { TasksChecklist } from "../components/TasksChecklist";
import { RecentPublicProjects } from "../components/RecentPublicProjects";
import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const Home: React.FC = withAuth(() => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);

  return (
    <div className="relative flex lg:flex-row flex-col-reverse gap-8 lg:gap-4 p-4">
      <Helmet>
        <title>Dashboard | Synqup</title>
      </Helmet>

      <div className="lg:w-1/3 flex flex-col gap-8 lg:gap-4">
        <TasksChecklist />
        <RecentLogs />
        {isMobile && <RecentPublicProjects />}
        {/*<TipOfTheDay />*/}
      </div>

      <div className="flex-1">
        <RecentProjects />
        {!isMobile && <RecentPublicProjects />}
      </div>
    </div>
  );
});
